<template>
  <div class="select" ref="select">
    <input type="text" :value="value" @focus="show" class="select__input" />
    <div class="select__view" @click="show">
      <span v-if="value" class="select__view-text">{{ selectedText }}</span>
      <span v-else class="select__placeholder select__view-text">{{
        placeholder
      }}</span>
    </div>
    <div
      class="select__container"
      :class="{ bottom: bottomHeight < 250 }"
      v-if="isShowed"
      v-click-outside="hide"
    >
      <input
        v-if="searchable"
        type="text"
        class="select__search"
        :value="filter"
        @input="filter = $event.target.value"
        placeholder="Search..."
        ref="search"
      />
      <div class="select__list">
        <div
          v-for="item in filtered"
          :key="item.value"
          class="select__item"
          :class="{ selected: item.value === value }"
          @click="selectHandler(item.value)"
        >
          {{ item.text }}
        </div>
      </div>
    </div>
    <span v-if="$slots.default" class="select__label">
      <slot />
    </span>
  </div>
</template>

<script>
const MIN_HEIGHT = 250;

export default {
  name: "vSelect",

  props: {
    value: {
      default: null,
      validator: (p) => p === null || ["string", "number"].includes(typeof p),
    },
    // should contain [{ value: <string|number>, text: <string|number> }] or [string,...]
    options: { type: Array, required: true },
    placeholder: { type: String, default: "Select value" },
    searchable: { type: Boolean, default: true },
  },

  data: () => ({
    isShowed: false,
    filter: "",
    bottomHeight: MIN_HEIGHT,
  }),

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    selectedText() {
      const obj =
        this.list.find(({ value }) => String(this.value) === String(value)) ||
        {};
      return obj.text || "";
    },

    list() {
      return this.options.map((el) => {
        if (["string", "number"].includes(typeof el))
          return {
            value: el,
            text: el,
          };

        return el;
      });
    },

    filtered() {
      const { filter, list } = this;
      const _filter = String(filter)
        .trim()
        .toLowerCase();
      if (!_filter) return list;
      return [...list].filter(({ text }) => {
        const _name = String(text)
          .trim()
          .toLowerCase();
        return _name.indexOf(_filter) !== -1;
      });
    },
  },

  methods: {
    show() {
      this.isShowed = true;
      this.checkHeight();
      this.searchFocus();
    },

    hide() {
      this.isShowed = false;
      this.clearFilter();
    },

    checkHeight() {
      this.bottomHeight =
        window.outerHeight - this.$refs.select.getBoundingClientRect().top;
    },

    searchFocus() {
      if (!this.searchable) return;
      this.$nextTick(() => {
        this.$refs.search.focus();
      });
    },

    selectHandler(value) {
      this.$emit("input", value);
      this.hide();
    },

    clearFilter() {
      this.filter = "";
    },
  },
};
</script>
