<template>
  <div>
    <validation-provider name="chronic conditions" rules="required" v-slot="{ errors }">
      <h3>
        Have you been diagnosed with any of the following conditions?
        (check all that apply)
      </h3>
      <div class="error-message mb" v-if="errors[0]">{{ errors[0] }}</div>
      <checkbox-list v-model="selected" :options="list" />
    </validation-provider>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { STORE_SET_CHRONIC } from "@/constants";
import CheckboxList from "@/components/ui/checkboxList";

export default {
  components: { CheckboxList },

  data: () => ({
    list: [
      { value: "copd", text: "COPD" },
      { value: "asthma", text: "Asthma" },
      { value: "bronchiectasis", text: "Bronchiectasis" },
      { value: "cystic_fibrosis", text: "Cystic Fibrosis" },
      { value: "pulmonary_fibrosis", text: "Pulmonary Fibrosis" },
      { value: "lung_cancer", text: "Lung Cancer" },
      { value: "anxiety", text: "Anxiety" },
      { value: "congestive_heart_failure", text: "Congestive Heart Failure" },
      { value: "depression", text: "Depression" },
      { value: "sinusitis", text: "Sinusitis" },
      { value: "allergic_rhinitis", text: "Allergic Rhinitis" },
      { value: "acid_reflux", text: "Acid reflux" },
      { value: "gerd", text: "GERD (Gastroesophageal Reflux Disease)" },
      { value: "obesity", text: "Obesity" },
      { value: "pulmonary_hypertension", text: "Pulmonary Hypertension" },
      { value: "pulmonary_nodules", text: "Pulmonary nodules" },
      { value: "sarcoidosis", text: "Sarcoidosis" },
      { value: "sleep_apnea", text: "Sleep apnea" },
      { value: "vocal_cord_dysfunction", text: "Vocal cord dysfunction" },
      { value: "stroke", text: "Stroke" },
      { value: null, text: "None" }
    ]
  }),

  computed: {
    ...mapState(["chronic_conditions"]),

    selected: {
      get() {
        const entries = Object.entries(this.chronic_conditions || {});
        const filtered = entries.filter(([, value]) => value);
        return filtered.map(([key]) => key);
      },
      set(arr) {
        const chronic_conditions = this.list.reduce(
          (acc, { value }) => ({ ...acc, [value]: arr.includes(value) }),
          {}
        );

        this[STORE_SET_CHRONIC](chronic_conditions);
      }
    }
  },

  methods: {
    ...mapActions([STORE_SET_CHRONIC])
  }
};
</script>
