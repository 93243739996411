import { scrolltop } from "@/modules/utils";

export default {
  methods: {
    validate(cb) {
      return this.$refs.form.validate().then((result) => {
        if (!result) return this.scroll();
        return cb();
      });
    },

    scroll() {
      this.$nextTick(() => {
        const elY = document
          .querySelector(".error-message")
          .getBoundingClientRect().top;
        const top = window.pageYOffset + elY - 180;

        scrolltop(top);
      });
    },
  },
};
