<template>
  <label>
    <input
      class="radio with-gap"
      type="radio"
      v-bind="$attrs"
      :value="current"
      :checked="checked"
      @input="$emit('input', current)"
    />
    <span>
      <slot />
    </span>
  </label>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    current: { type: [String, Number, Boolean], required: true },
    value: {
      default: null,
      validator: p =>
        p === null || ["string", "number", "boolean"].includes(typeof p)
    }
  },

  computed: {
    checked() {
      return this.value === this.current;
    }
  }
};
</script>
