<template>
  <div class="checkboxes">
    <label
      v-for="(item, index) in options"
      :key="index"
      class="checkboxes__item"
    >
      <input
        v-if="item.value === null"
        class="with-gap"
        type="radio"
        v-model="none"
        @change="changeNoneHandler"
      />
      <input
        v-else
        class="filled-in"
        type="checkbox"
        :value="item.value"
        v-model="selected"
      />
      <span>{{ item.text }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "CheckboxList",

  props: {
    value: { type: Array, default: () => [] },
    options: { type: Array, required: true },
  },

  data: () => ({
    none: false,
  }),

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(data) {
        this.none = false;
        this.$emit("input", data);
      },
    },
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value.some((value) => [null, "null"].includes(value))) {
          this.none = null;
        }
      },
    },
  },

  methods: {
    changeNoneHandler() {
      return this.$emit("input", [null]);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkboxes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__item {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
