<template>
  <div class="vape">
    <h3>Do you vape?</h3>
    <validation-provider name="vape" rules="required" v-slot="{ errors }">
      <div class="error-message mb" v-if="errors[0]">{{ errors[0] }}</div>
      <radio-list v-model="vapeValue" :options="vapeList" />
    </validation-provider>

    <template v-if="vape && !short">
      <h3 class="vape__values">How much?</h3>
      <validation-provider name="ml/day" rules="required" v-slot="{ errors }">
        <v-select
          class="vape__item"
          v-model="mlValue"
          :options="mlList"
          :class="{ error: errors[0] }"
        >
          ml/day
        </v-select>
        <div class="error-message mb" v-if="errors[0]">{{ errors[0] }}</div>
      </validation-provider>
      <validation-provider name="mg/day" rules="required" v-slot="{ errors }">
        <v-select
          v-model="mgValue"
          :options="mgList"
          :class="{ error: errors[0] }"
        >
          mg/day
        </v-select>
        <div class="error-message mt" v-if="errors[0]">{{ errors[0] }}</div>
      </validation-provider>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { STORE_SET_CHARACTERISTICS } from "@/constants";
import RadioList from "@/components/ui/radioList";
import vSelect from "@/components/ui/select";

export default {
  components: { RadioList, vSelect },

  props: {
    short: { type: Boolean, default: false },
  },

  data: () => ({
    vapeList: [
      { value: true, text: "Yes" },
      { value: false, text: "No" },
    ],
    mlList: [
      "I don’t know",
      "1-2",
      "3-4",
      "5-6",
      "7-8",
      "9-10",
      "11-12",
      "13-15",
      "16-20",
      "20-25",
      "26+",
    ],
    mgList: [
      "I don’t know",
      "0",
      "1-3",
      "4-6",
      "7-12",
      "13-18",
      "19-36",
      "37+",
    ],
  }),

  computed: {
    ...mapState({
      vape: ({ characteristics }) => characteristics.vape,
      vape_value_ml: ({ characteristics }) => characteristics.vape_value_ml,
      vape_value_mg: ({ characteristics }) => characteristics.vape_value_mg,
    }),

    vapeValue: {
      get() {
        return this.vape;
      },
      set(vape) {
        const { [STORE_SET_CHARACTERISTICS]: setParams } = this;
        setParams({
          vape,
          ...(vape ? {} : { vape_value_ml: null, vape_value_mg: null }),
        });
      },
    },

    mlValue: {
      get() {
        return this.vape_value_ml;
      },
      set(vape_value_ml) {
        const { [STORE_SET_CHARACTERISTICS]: setParams } = this;
        setParams({ vape_value_ml });
      },
    },

    mgValue: {
      get() {
        return this.vape_value_mg;
      },
      set(vape_value_mg) {
        const { [STORE_SET_CHARACTERISTICS]: setParams } = this;
        setParams({ vape_value_mg });
      },
    },
  },
  methods: mapActions([STORE_SET_CHARACTERISTICS]),
};
</script>

<style lang="scss" scoped>
.vape {
  &__values {
    margin-top: 1.5rem;
  }

  &__item {
    margin-bottom: 1rem;
  }
}
</style>
