<template>
  <div class="radio-list">
    <radio
      v-for="(item, index) in list"
      :key="index"
      :current="item.value"
      :value="value"
      class="radio-list__item"
      @input="$emit('input', $event)"
      >{{ item.text }}</radio
    >
  </div>
</template>

<script>
import Radio from "@/components/ui/radio";

export default {
  components: { Radio },

  props: {
    value: {
      default: null,
      validator: (p) =>
        p === null || ["string", "number", "boolean"].includes(typeof p),
    },
    // should contain [{ value: <null|string|number|boolean>, text: <string|number> }]
    options: { type: Array, required: true },
  },

  computed: {
    list() {
      return this.options.map((el) => {
        if (["string", "number"].includes(typeof el))
          return {
            value: el,
            text: el,
          };

        return el;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-list {
  display: flex;
  align-items: center;
  width: 100%;

  &.col {
    flex-direction: column;
    align-items: flex-start;
  }

  &.col &__item:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  &__item {
    flex: 1;
  }
}
</style>
