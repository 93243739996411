<template>
  <div>
    <h3>Do you smoke?</h3>
    <validation-provider name="smoke" rules="required" v-slot="{ errors }">
      <div class="error-message mb" v-if="errors[0]">{{ errors[0] }}</div>
      <radio-list v-model="smokeValue" :options="smokeList" />
    </validation-provider>

    <template v-if="smoke && !short">
      <h3 class="packs">How many packs per week?</h3>
      <validation-provider name="packs" rules="required" v-slot="{ errors }">
        <v-select
          v-model="packsValue"
          :options="packsList"
          :class="{ error: errors[0] }"
        />
        <div class="error-message mt" v-if="errors[0]">{{ errors[0] }}</div>
      </validation-provider>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { STORE_SET_CHARACTERISTICS } from "@/constants";
import RadioList from "@/components/ui/radioList";
import vSelect from "@/components/ui/select";

export default {
  components: { RadioList, vSelect },

  props: {
    short: { type: Boolean, default: false },
  },

  data: () => ({
    smokeList: [
      { value: true, text: "Yes" },
      { value: false, text: "No" },
    ],
    packsList: ["< 1", "1", "2-3", "4-5", "5-7", "8+"],
  }),

  computed: {
    ...mapState({
      smoke: ({ characteristics }) => characteristics.smoke,
      smoke_value: ({ characteristics }) => characteristics.smoke_value,
    }),

    smokeValue: {
      get() {
        return this.smoke;
      },
      set(smoke) {
        const { [STORE_SET_CHARACTERISTICS]: setParams } = this;
        return setParams({ smoke, ...(smoke ? {} : { smoke_value: null }) });
      },
    },

    packsValue: {
      get() {
        return this.smoke_value;
      },
      set(smoke_value) {
        const { [STORE_SET_CHARACTERISTICS]: setParams } = this;
        setParams({ smoke_value });
      },
    },
  },

  methods: mapActions([STORE_SET_CHARACTERISTICS]),
};
</script>
<style lang="scss" scoped>
.packs {
  margin-top: 1.5rem;
}
</style>
